import Str from '@supercharge/strings';

export default class InvoiceNumberGenerator {
    static make(format: string, padding: number, number: number): string {
        return Str(format)
            .replace('%number%', number.toString().padStart(padding, '0'))
            .replace('%year%', moment().format('YYYY').toString())
            .replace('%year2%', moment().format('YY').toString())
            .replace('%month%', moment().format('MM').toString())
            .toString();
    }
}
